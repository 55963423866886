<template>
  <div>
    <b-modal ref="modalRef" title="Download Study" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" ok-title="Download Study" @ok="downloadStudy">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-form>
        <b-form-checkbox class="mt-1" v-model="exportStudy.include_reports" @change="exportStudyChanged">
          Include Reports
        </b-form-checkbox>
        <b-form-checkbox class="mt-1" v-model="exportStudy.include_viewer" @change="exportStudyChanged">
          Include DICOM Viewer
        </b-form-checkbox>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import dicomWeb from '../common/dicomweb'
import webServices from '../common/webServices'

export default {
  name: 'downloadStudy',
  components: {
  },
  data() {
    return {
      entry: webServices.getEmptyWorklistEntry(),
      exportStudy: {
          "include_reports": true,
          "include_viewer": false
      },
    }
  },
  computed: {
    title() {
      return webServices.getTitleForEntry(this.entry)
    }
  },
  methods: {
    show(worklistEntry) {
      this.entry = worklistEntry
      this.studyUid = worklistEntry.study_uid
      this.exportStudy = {...this.exportStudy, ...this.$store.state.exportStudy}
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    exportStudyChanged() {
      webServices.updateUserSetting("exportStudy", this.exportStudy)
      .then(() => {
        this.$log.debug("Updating exportStudy user setting")
      })
      .catch(err => {
        this.$log.error("Error updating cached settings: "+err)
      })
      .finally(() => {
        this.$store.commit("changeExportStudy", this.exportStudy)
      })
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        solid: true,
        title: 'INSPIRE PACS',
        variant: variant,
      })
    },
    downloadStudy() {
      if (this.studyUid != '') {
        const toastMsg = "Download started [" + webServices.getTitleForEntry(this.entry) + "]..."
        this.displayToast(toastMsg, 'info')
        dicomWeb.downloadStudy(this.entry)
        this.hide()
      }
    }
  }
};
</script>
<style scoped>
</style>