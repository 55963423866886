<template>
  <div>
    <b-modal ref="modalRef" title="Select Comparison Studies" size="lg" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      no-close-on-esc no-close-on-backdrop hide-header-close
      content-class="shadow" ok-only ok-variant="secondary" @ok="handleOk">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
      <b-form>
        <b-form-checkbox-group class="mt-2" v-model="selectedStudies" :options="availableStudies" stacked>
        </b-form-checkbox-group>
        <div class="float-right">
          <b-button @click="selectAll" variant="secondary">Select All</b-button>
          <b-button class="ml-1" @click="selectNone" variant="secondary">Select None</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'viewerStudySelector',
  components: {
  },
  data() {
    return {
      selectedStudies: []
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    entry() {
      const emptyEntry = webServices.getEmptyWorklistEntry()
      try {
        const entry = this.$store.getters.worklistEntryForStudy(this.studyList[this.selectedStudyI].study_uid)
        if (entry != null) {
          return entry
        }
      }
      catch {
        return emptyEntry
      }
      return emptyEntry
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    studyList() {
      return this.$store.state.viewerStudyList
    },
    selectedStudyI() {
      return this.$store.state.viewerSelectedStudyI
    },
    availableStudies() {
      var studies = []
      for (var i = 0; i < this.studyList.length; i++) {
        const s = this.studyList[i]
        if (!s.oo && (i != this.selectedStudyI)) {
          const studyDateTime = (s.study_date_time == null) ? '---' : new Date(s.study_date_time).toLocaleString(this.locale)
          studies.push({
            html: `${studyDateTime}&nbsp;[${s.modality.trim()}]&nbsp;${s.study_desc}`,
            value: s.study_uid,
            disabled: false
          })
        }
      }
      return studies
    }
  },
  methods: {
    show() {
      this.selectedStudies = []
      this.$refs['modalRef'].show()
    },
    handleOk() {
      this.$log.debug(`Selected ${this.selectedStudies.length} priors.`)
      this.$emit('selectedStudies', this.selectedStudies)
      this.$refs['modalRef'].hide()
    },
    selectAll() {
      this.selectedStudies = []
      for (var i = 0; i < this.availableStudies.length; i++) {
        this.$log.debug(`Adding study_uid=${this.availableStudies[i].value}`)
        this.selectedStudies.push(this.availableStudies[i].value)
      }
      this.handleOk()
    },
    selectNone() {
      this.selectedStudies = []
      this.handleOk()
    }
  }
};
</script>
<style scoped>
</style>