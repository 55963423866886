<template>
  <div>
    <WorksInProgress ref="wDialog" title="Print"/>
    <b-modal ref="modalRef" title="Comments Viewer"
      size="xl" scrollable
      header-bg-variant="secondary" header-text-variant="light"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      hide-footer content-class="shadow">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-alert variant="info" class="font-weight-bold" show>
            {{ entry.patient_name }} {{ entry.patient_age }} | {{ entry.sex }} | {{ entry.patient_id }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item class="bg-dark text-light">
                <b-button variant="secondary" size="sm" @click="showAllergies=!showAllergies" :showAllergies="showAllergies" v-b-toggle.collapse-a>{{showAllergies ? '−' : '+'}}</b-button>
                <span class="ml-2 font-weight-bold">Allergies</span> <b-badge variant="primary" pill>0</b-badge>
                <b-collapse id="collapse-a"><div class="mt-2 bg-dark text-light">No allergies found for this patient (WiP).</div></b-collapse>
              </b-list-group-item>
              <b-list-group-item class="bg-dark text-light">
                <b-button variant="secondary" size="sm" @click="showDiagnosis=!showDiagnosis" :showDiagnosis="showDiagnosis" v-b-toggle.collapse-b>{{showDiagnosis ? '−' : '+'}}</b-button>
                <span class="ml-2 font-weight-bold">Diagnosis</span> <b-badge variant="primary" pill>0</b-badge>
                <b-collapse id="collapse-b"><div class="mt-2 bg-dark text-light">No diagnosis found for this patient (WiP).</div></b-collapse>
              </b-list-group-item>
              <b-list-group-item class="bg-dark text-light">
                <b-button variant="secondary" size="sm" @click="showOrders=!showOrders" :showOrders="showOrders" v-b-toggle.collapse-c>{{showOrders ? '−' : '+'}}</b-button>
                <span class="ml-2 font-weight-bold">Orders</span> <b-badge variant="primary" pill>0</b-badge>
                <b-collapse id="collapse-c"><div class="mt-2 bg-dark text-light">No orders found for this patient (WiP).</div></b-collapse>
              </b-list-group-item>
              <b-list-group-item class="bg-dark text-light">
                <b-button variant="secondary" size="sm" @click="showQuestionnaire=!showQuestionnaire" :showQuestionnaire="showQuestionnaire" v-b-toggle.collapse-d>{{showQuestionnaire ? '−' : '+'}}</b-button>
                <span class="ml-2 font-weight-bold">Questionnaire</span> <b-badge variant="primary" pill>0</b-badge>
                <b-collapse id="collapse-d"><div class="mt-2 bg-dark text-light">No questionnaire found for this patient (WiP).</div></b-collapse>
              </b-list-group-item>
              <b-list-group-item class="bg-dark text-light">
                <b-container>
                  <b-row>
                    <b-col cols="2"><label>Operator:</label></b-col>
                    <b-col cols="8"><b-form-select class="bg-light" id="newCommentOperator" v-model="newCommentOperator" :options="operatorOptions"></b-form-select></b-col>
                    <b-col cols="2" class="ml-auto"><b-form-checkbox id="newCommentFlag" v-model="newCommentFlag">Flagged</b-form-checkbox></b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="2"><label>Comments:</label></b-col>
                    <b-col cols="10"><b-form-textarea class="bg-light" id="newComment" v-model="newComment"></b-form-textarea></b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col cols="2"></b-col>
                    <b-col cols="10"><b-button variant="secondary">Add Comment</b-button></b-col>
                  </b-row>
                </b-container>
              </b-list-group-item>
              <b-list-group-item class="bg-dark text-light">
                <b-button variant="secondary" size="sm" @click="showComments=!showComments" :showComments="showComments" v-b-toggle.collapse-e>{{showComments ? '−' : '+'}}</b-button>
                <span class="ml-2 font-weight-bold">Comment History</span> <b-badge variant="primary" pill>0</b-badge>
                <b-collapse id="collapse-e" visible>
                  <b-table class="mt-2 small" striped head-variant="dark" table-variant="secondary" :items="commentItems" :fields="commentFields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-compare="sortCompare" primary-key="date_time">
                    <template #cell(flag)="data">
                      <template v-if="data.value"><b-icon icon="flag-fill"></b-icon></template>
                    </template>
                  </b-table>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2 ml-auto col-auto">
            <b-button class="ml-2" variant="secondary" @click="print"><b-icon icon="printer"></b-icon> Print</b-button>
            <b-button class="ml-2" variant="secondary" @click="hide">Close</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import webServices from '../common/webServices.js'
import WorksInProgress from './WorksInProgress.vue'

export default {
  name: 'comments',
  components: {
    WorksInProgress
  },
  data() {
    return {
      newCommentOperator: null,
      newCommentFlag: false,
      newComment: '',
      operatorOptions: [
        { value: null, text: "<WiP>" },
      ],
      showAllergies: false,
      showComments: true,
      showDiagnosis: false,
      showOrders: false,
      showQuestionnaire: false,
      sortBy: 'date_time',
      sortDesc: true,
      commentFields: [ 
        { key: 'flag', label: '', sortable: false },
        { key: 'comment', label: 'Comments', sortable: false },
        { key: 'author', label: 'By', sortable: false },
        { key: 'date_time', label: 'Commented At', sortable: true },
      ]
    }
  },
  computed: {
    commentItems() {
      return [
        {
          'flag': false,
          'comment': 'Works in Progress (no flag)',
          'author': 'Todd Jensen',
          'date_time': '04/20/2021 10:00:00',
          'date_dcm': '20210402',
          'time_dcm': '100000'
        },
        {
          'flag': true,
          'comment': 'Works in Progress (flagged)',
          'author': 'Todd Jensen',
          'date_time': '04/20/2021 10:02:00',
          'date_dcm': '20210402',
          'time_dcm': '100200'
        }
      ]
    },
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.$store.getters.selectedStudyUid())
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    studyDate() {
        return webServices.parseDA(this.entry.study_date_dcm)
    },
    studyTime() {
        return webServices.parseTM(this.entry.study_time_dcm)
    }
  },
  methods: {
    show() {
      this.$refs['modalRef'].show()
    },
    hide() {
      this.$refs['modalRef'].hide()
    },
    print() {
      // +TODO+ Handle print
      this.$refs['wDialog'].show()
    },
    sortCompare(aRow, bRow, key /*, sortDesc, formatter, compareOptions, compareLocale*/) {
      if (key == 'date_time') {
        const aD = aRow['date_dcm']
        const bD = bRow['date_dcm']
        if (aD == bD) {
          const aT = aRow['time_dcm']
          const bT = bRow['time_dcm']
          return aT - bT
        }
        return aD - bD
      }
      else {
        // Fallback to default sort routine.
        //
        return false;
      }
    }
  }
};
</script>
<style scoped>
</style>